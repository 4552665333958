import { UseQueryKey, FetchGET, FetchPOST, FetchPUT } from './useFetch.js'
import { axiosGet, axiosPost, axiosPut, axiosFileDownload } from './useAxios.js';

import { URL_API_LIST, URI_QUERY_LIST } from './useMopicAPIConfig.js'

export const QUERYKEY_PRODUCT = {
    getProductListInfo: UseQueryKey("GET", "productinfo"),
    // TODO
    getProductInfo: UseQueryKey("GET", "productinfo"),
    addProduct: UseQueryKey("POST", "addproductinfo"),
    changeProductInfo: UseQueryKey("PUT", "changeproductinfo"),
    changeProductStatus: UseQueryKey("POST", "changeproductstatus"),
    deleteCustomer: UseQueryKey("DELETE", "productinfo"),
    getLabelInfo: UseQueryKey("GET", "productlabelinfo"),
    getProductStatusList: UseQueryKey("GET", "productstatusinfo"),
    getEyeTrackingTypeList: UseQueryKey("GET", "eyetrackinginfo"),
    issueBlinkSN: UseQueryKey("POST", "issueblinksn"),
    changeProductValid: UseQueryKey("POST", "changeproductvalid")
};

export const getValidTimeList = () => {
    const arrdata = [
        { idx: 0, value: -1, text: 'permanent' },
        { idx: 1, value: 2629800000, text: '1 month' },
        { idx: 2, value: 5259600000, text: '2 month' },
        { idx: 3, value: 7889400000, text: '3 month' },
        { idx: 4, value: 10519200000, text: '4 month' },
        { idx: 5, value: 13149000000, text: '5 month' },
        { idx: 6, value: 15778800000, text: '6 month' },
        { idx: 7, value: 18408600000, text: '7 month' },
        { idx: 8, value: 21038400000, text: '8 month' },
        { idx: 9, value: 23668200000, text: '9 month' },
        { idx: 10, value: 26298000000, text: '10 month' },
        { idx: 11, value: 28927800000, text: '11 month' },
        { idx: 12, value: 31557600000, text: '12 month' }

    ];

    return arrdata;
}
export const getConvertValidTime = (finddata) => {
    const arrdata = [
        { idx: 0, value: -1, text: 'permanent' },
        { idx: 1, value: 2629800000, text: '1 month' },
        { idx: 2, value: 5259600000, text: '2 month' },
        { idx: 3, value: 7889400000, text: '3 month' },
        { idx: 4, value: 10519200000, text: '4 month' },
        { idx: 5, value: 13149000000, text: '5 month' },
        { idx: 6, value: 15778800000, text: '6 month' },
        { idx: 7, value: 18408600000, text: '7 month' },
        { idx: 8, value: 21038400000, text: '8 month' },
        { idx: 9, value: 23668200000, text: '9 month' },
        { idx: 10, value: 26298000000, text: '10 month' },
        { idx: 11, value: 28927800000, text: '11 month' },
        { idx: 12, value: 31557600000, text: '12 month' }

    ];

    if (finddata)
    {
        finddata = +finddata;
        if (arrdata.find(x => x.value === finddata))
        {
            return arrdata.find(x => x.value === finddata).text;
        }
        else
        {
            return '';
        }
    }
    else
    {
        return '';
    }
}
export const getProductStatusList = (objOptions) => {
    const querykey = QUERYKEY_PRODUCT['getProductStatusList'];
    let req_url = URL_API_LIST['API_PRODUCT_STATUS'] + "?cat=typelist";

    return FetchGET(req_url, querykey, objOptions);
}
export const getEyeTrackingTypeList = (objOptions) => {
    const querykey = QUERYKEY_PRODUCT['getEyeTrackingTypeList'];
    let req_url = URL_API_LIST['API_PRODUCT_EYETRACKER'] + "?cat=type";

    return FetchGET(req_url, querykey, objOptions);
}

export const axiosGetProductLabelInfo = async (descriptor) => {
    if (!descriptor)
    {
        return;
    }

    let req_url = URL_API_LIST['API_PRODUCT_LABEL'];

    let str_query = "";
    if (descriptor.keys.product_id)
    {
        str_query += URI_QUERY_LIST['SEARCH_QUERY_PRODUCTID'] + "=" + encodeURIComponent(descriptor.keys.product_id);
    }

    if (str_query)
    {
        req_url += "?" + str_query;
    }

    await axiosGet(req_url, descriptor);
}
export const axiosGetEyeTrackerTypeList = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_PRODUCT_EYETRACKER'] + "?cat=type";

    await axiosGet(req_url, descriptor);
}
export const axiosGetEyeTrackerLicenseTypeList = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_PRODUCT_EYETRACKER'] + "?cat=license_type";

    await axiosGet(req_url, descriptor);
}
export const axiosGetUsedModelNameList = async (descriptor) => {
    if (!descriptor)
        return;

    let req_url = URL_API_LIST['API_PRODUCT_USEDMODELNAME_LIST'] + "?unique=true";

    await axiosGet(req_url, descriptor);
}

export const axiosPostProductValidTimeChange = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_ACTIVATE'] + "/0";

    await axiosPost(descriptor);
}

export const axiosPostProductIssued = async (descriptor) => {
    if (!descriptor)
        return;
    descriptor.url = URL_API_LIST['API_PRODUCT_ISSUED'];

    await axiosPost(descriptor);
}
export const axiosPostProductModelSNIssued = async (descriptor) => {
    if (!descriptor)
        return;
    descriptor.url = URL_API_LIST['API_PRODUCT_MODELSN_ISSUED'];

    await axiosPost(descriptor);
}
export const axiosPostProductAdd = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_INFORMATION'];

    await axiosPost(descriptor);
}
export const axiosPostProductAddWithCSVFile = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_INFORMATION_FILE'];

    await axiosPost(descriptor);
}

export const axiosPutProductInfoChange = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_INFORMATION'];

    await axiosPut(descriptor);
}
export const axiosPutProductInformationChangeWithCSVFile = async (descriptor) => {
    if (!descriptor)
        return;

    descriptor.url = URL_API_LIST['API_PRODUCT_INFORMATION_FILE'];

    await axiosPut(descriptor);
}

export const getProductInfo = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_PRODUCT['getProductInfo'];

    let req_url = URL_API_LIST['API_PRODUCT_INFORMATION'];

    let str_query = "";
    if (objOptions?.keys)
    {
        const keys = objOptions?.keys;

        if (keys.product_id)
        {
            str_query += URI_QUERY_LIST['SEARCH_QUERY_PRODUCTID'] + "=" + encodeURIComponent(keys.product_id);
        }
        else
        {
            if (keys.customer_id)
                str_query += URI_QUERY_LIST['SEARCH_QUERY_CUSTOMERID'] + "=" + encodeURIComponent(keys.customer_id) + "&";
            if (keys.customer_company)
                str_query += URI_QUERY_LIST['SEARCH_QUERY_COMPANYNAME'] + "=" + encodeURIComponent(keys.customer_company) + "&";
            if (keys.model_name)
                str_query += URI_QUERY_LIST['SEARCH_QUERY_MODELNAME'] + "=" + encodeURIComponent(keys.model_name) + "&";
            if (keys.model_serialnumber)
                str_query += URI_QUERY_LIST['SEARCH_QUERY_MODELSERIALNUMBER'] + "=" + encodeURIComponent(keys.model_serialnumber) + "&";
            if (keys.product_status)
                str_query += URI_QUERY_LIST['SEARCH_QUERY_PRODUCTSTATUS'] + "=" + encodeURIComponent(keys.product_status) + "&";
            if (keys.product_serialnumber)
                str_query += URI_QUERY_LIST['SEARCH_QUERY_PRODUCTSERIAL'] + "=" + encodeURIComponent(keys.product_serialnumber) + "&";

            if (keys.manufacture_start_date && keys.manufacture_end_date)
            {
                str_query += URI_QUERY_LIST['SEARCH_QUERY_PRODUCTMANUFACTSTART'] + "=" + encodeURIComponent(keys.manufacture_start_date) + "&";
                str_query += URI_QUERY_LIST['SEARCH_QUERY_PRODUCTMANUFACTEND'] + "=" + encodeURIComponent(keys.manufacture_end_date);
            }
        }
    }

    if (str_query)
    {
        req_url += "?" + str_query;
    }

    return FetchGET(req_url, querykey, objOptions);
}
export const getProductListInfo = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_PRODUCT['getProductListInfo'];
    let req_url = URL_API_LIST['API_PRODUCT_LIST'];

    let str_query = "";
    if (objOptions?.keys)
    {
        const keys = objOptions?.keys;

        if (keys.customer_company)
        {
            str_query += URI_QUERY_LIST['SEARCH_QUERY_COMPANYNAME'] + "=" + encodeURIComponent(keys.customer_company);
        }
        if (keys.model_name)
        {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_MODELNAME'] + "=" + encodeURIComponent(keys.model_name);
        }
        if (keys.model_serialnumber)
        {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_MODELSERIALNUMBER'] + "=" + encodeURIComponent(keys.model_serialnumber);
        }
        if (keys.status_idx)
        {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_PRODUCTSTATUS'] + "=" + encodeURIComponent(keys.status_idx);
        }
        if (keys.product_serialnumber)
        {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_PRODUCTSERIAL'] + "=" + encodeURIComponent(keys.product_serialnumber);
        }
        if (keys.manufacture_start_date && keys.manufacture_end_date)
        {
            if (str_query.length > 1) str_query += "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_PRODUCTMANUFACTSTART'] + "=" + encodeURIComponent(keys.manufacture_start_date) + "&";
            str_query += URI_QUERY_LIST['SEARCH_QUERY_PRODUCTMANUFACTEND'] + "=" + encodeURIComponent(keys.manufacture_end_date);
        }
    }

    if (str_query)
    {
        req_url += "?" + str_query;
    }

    return FetchGET(req_url, querykey, objOptions);
}
export const getProductLabelInfo = (objOptions) => {
    if (!objOptions || !objOptions.keys || !objOptions.keys.product_id)
    {
        return;
    }

    const querykey = QUERYKEY_PRODUCT['getLabelInfo'];
    let req_url = URL_API_LIST['API_PRODUCT_LABEL'];

    let str_query = "";
    if (objOptions.keys.product_id)
    {
        str_query += URI_QUERY_LIST['SEARCH_QUERY_PRODUCTID'] + "=" + encodeURIComponent(objOptions.keys.product_id);
    }

    if (str_query)
    {
        req_url += "?" + str_query;
    }

    return FetchGET(req_url, querykey, objOptions);
}
export const getProductCount = (objOptions) => {
    if (!objOptions)
    {
        return;
    }
}

export const addProduct = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_PRODUCT['addProduct'];

    let req_url = URL_API_LIST['API_PRODUCT_INFORMATION'];

    return FetchPOST(req_url, querykey, objOptions);
}

export const changeProductInfo = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_PRODUCT['changeProductInfo'];

    let req_url = URL_API_LIST['API_PRODUCT_INFORMATION'];

    return FetchPUT(req_url, querykey, objOptions);
}
export const changeProductStatus = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_PRODUCT['changeProductStatus'];

    let req_url = URL_API_LIST['API_PRODUCT_STATUS'] + "/0";

    return FetchPOST(req_url, querykey, objOptions);
}

export const deleteCustomer = (objOptions) => {
    if (!objOptions)
    {
        return;
    }
}

export const issueProductEyetracker = (objOptions) => {
    if (!objOptions)
    {
        return;
    }
    const querykey = QUERYKEY_PRODUCT['issueBlinkSN'];

    let req_url = URL_API_LIST['API_PRODUCT_EYETRACKER'] + '/2/issue';

    return FetchPOST(req_url, querykey, objOptions);
}
export const issueProductEyetrackerWindowOnly = (objOptions) => {
    if (!objOptions)
    {
        return;
    }
    const querykey = QUERYKEY_PRODUCT['issueBlinkSN'];

    let req_url = URL_API_LIST['API_PRODUCT_EYETRACKER_WINDOWONLY'] + '/2/issue';

    return FetchPOST(req_url, querykey, objOptions);
}

export const fileDownloadProductTemplate = async (descriptor) => {
    if (!descriptor)
    {
        return;
    }

    let req_url = URL_API_LIST['API_PRODUCT_FILEDOWNLOAD'];
    req_url += "?fn=" + descriptor.keys.file_name;

    await axiosFileDownload(req_url, descriptor);
}

// TODO
export const getProductEyetracker = (objOptions) => {
    if (!objOptions)
    {
        return;
    }
    const querykey = QUERYKEY_PRODUCT['getEyeTrackingTypeList'];
    let req_url = URL_API_LIST['API_PRODUCT_EYETRACKER'];
    let str_query = "";

    if (objOptions?.keys)
    {
        str_query += "?cat=info&et=" + objOptions.keys?.eyetracker_idx + "&&pid=" + objOptions.keys?.product_id;
    }

    if (str_query)
    {
        req_url += str_query;
    }
    return FetchGET(req_url, querykey, objOptions);
}

export const changeProductValidTime = (objOptions) => {
    if (!objOptions)
    {
        return;
    }

    const querykey = QUERYKEY_PRODUCT['changeProductValid'];

    let req_url = URL_API_LIST['API_PRODUCT_ACTIVATE'] + "/0";

    return FetchPOST(req_url, querykey, objOptions);
}