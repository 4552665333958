import { useQuery, useMutation } from 'react-query';
import { MOPICAPI_KEY } from './useMopicAPIConfig';

export const UseQueryKey = (method_type, postfix) => {
    let key = "";
    switch (method_type)
    {
        case "GET": key = "mkge"; break;
        case "POST": key = "mkpo"; break;
        case "PUT": key = "mkpu"; break;
        case "PATCH": key = "mkpa"; break;
        case "DELETE": key = "mkde"; break;
        default: key = "";
    }

    key += postfix;

    return key;
}

export const FetchGET = (url, query_key, query_options) => {
    return useQuery([query_key],
        async () => {
            const fetch_option = {
                method: "GET",
                headers: {
                    'api-key': MOPICAPI_KEY['COMMON']
                }
            }

            const res = await fetch(url, fetch_option);
            return await res.json();
        },
        {
            refetchOnMount: true,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                if (query_options?.callback_success)
                    query_options.callback_success(data);

            },
            onError: (error) => {
                if (query_options?.callback_error)
                    query_options.callback_error(error);
            },
            onSettled: (data, error) => {
            },
        }
    );
}

export const FetchPOST = (url, querykey, mutate_options) => {
    return useMutation(
        () => {
            const fetch_option = {
                method: "POST",
                headers: {
                    'content-type': 'application/json',
                    'api-key': MOPICAPI_KEY['COMMON']
                },
                body: JSON.stringify(mutate_options.body)
            }

            fetch(url, fetch_option);
        },
        {
            mutationKey: querykey,
            onSuccess: (data, variables, context) => {
                if (mutate_options?.callback_success)
                    mutate_options.callback_success(data, variables, context);
            },
            onError: (error) => {
                if (mutate_options?.callback_error)
                    mutate_options.callback_error(error);
            },
            onSettled: (data, error, variables, context) => {
            },
        }
    );
}

export const FetchPUT = (url, querykey, mutate_options) => {
    return useMutation(
        () => {
            const fetch_option = {
                method: "PUT",
                headers: {
                    'content-type': 'application/json',
                    'api-key': MOPICAPI_KEY['COMMON']
                },
                body: JSON.stringify(mutate_options.body)
            }

            fetch(url, fetch_option);
        },
        {
            mutationKey: querykey,
            onSuccess: (data, variables, context) => {
                if (mutate_options?.callback_success)
                    mutate_options.callback_success(data, variables, context);
            },
            onError: (error) => {
                if (mutate_options?.callback_error)
                    mutate_options.callback_error(error);
            },
            onSettled: (data, error, variables, context) => {
            },
        }
    );
}

export const FetchDELETE = (url, querykey, mutate_options) => {
    return useMutation(
        () => {
            const fetch_option = {
                method: "DELETE",
                headers: {
                    'content-type': 'application/json',
                    'api-key': MOPICAPI_KEY['COMMON']
                },
                body: JSON.stringify(mutate_options.body)
            }

            fetch(url, fetch_option);
        },
        {
            mutationKey: querykey,
            onSuccess: (data, variables, context) => {
                if (mutate_options?.callback_success)
                    mutate_options.callback_success(data, variables, context);
            },
            onError: (error) => {
                if (mutate_options?.callback_error)
                    mutate_options.callback_error(error);
            },
            onSettled: (data, error, variables, context) => {
            },
        }
    );
}