/* eslint-disable */ 

import React, {useEffect,useState} from 'react';
import './resources/scss/common.scss';
import Ir from './pages/ir/Ir';
import Ir_en from './pages/ir/Ir_en';
import Introduce from './pages/introduce/Introduce';
import Introduce_en from './pages/introduce/Introduce_en';
import Introduce_jp from './pages/introduce/Introduce_jp';
import Infocomm2022 from './pages/infocomm/Infocomm2022';
import LearnMore from './pages/learnmore/LearnMore';


import {Link, Route, Switch, useHistory } from 'react-router-dom';

import {  Button} from 'react-bootstrap';
import ReactGA from 'react-ga';
import Tech from './pages/tech/Tech';
import Tech_en from './pages/tech/Tech_en';
import Dhaula from './pages/manuals/Dhaula';
import Manual from './pages/manuals/Manuals';

/*
const useTitle = (initialTitle) => {
  const [title, setTitle] = useState(initialTitle);
  const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
  };
  useEffect(updateTitle, [title]);
  return setTitle;
};
*/

function App() {

  let history  = useHistory();

  console.log('MOPICLABS');
  useEffect(()=>{
    ReactGA.initialize("G-XXC02JLHDQ");
    history.listen((location) => {
      // ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
  },[])


  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <div className="root_wrap gate">
            <div className="header_wrap">
              <header className="header main-header">
                <nav className="header_nav wrap_padding">
                  <a href="/" className="header_logo"><span className="blind-text">MOPIC</span></a>
                </nav>
              </header>
            </div>
            <div className="cont">
                <ul className="gate_group col-3">
                  <li>
                    <p className="tit">IR</p>
                    <div className="btns">
                      <Link to="/ir/kr" className='btn-gray btn-lg btn'>KR</Link>
                      <Link to="/ir/en" className='btn-gray btn-lg btn'>EN</Link>
                    </div>
                  </li>
                  <li>
                    <p className="tit">Company</p>
                    <div className="btns">
                      <Link to="/introduce/kr" className='btn-gray btn-lg btn'>KR</Link>
                      <Link to="/introduce/en" className='btn-gray btn-lg btn'>EN</Link>
                      <Link to="/introduce/jp" className='btn-gray btn-lg btn'>JP</Link>
                    </div>
                  </li>
                  {/* <li>
                    <p className="tit">Tech</p>
                    <div className="btns">
                      <Link to="/tech/kr" className='btn-gray btn-lg btn'>KR</Link>
                    </div>
                  </li> */}
                </ul>
              {/* <div className="cont_inner">


                <Link to="/ir/kr" className='btn-primary btn-lg btn'>IR (KR)</Link>
                <Link to="/ir/en" className='btn-primary btn-lg btn'>IR (EN)</Link>
                
                <Link to="/introduce/kr" className='btn-primary btn-lg btn'>Company (KR)</Link>
                <Link to="/introduce/en" className='btn-primary btn-lg btn'>Company (EN)</Link>
                <Link to="/tech/kr" className='btn-primary btn-lg btn'>Technology (KR)</Link> */}
                {/* <Link to="/tech/en" className='btn-primary btn-lg btn'>Technology (EN)</Link> */}
                {/* <Link to="/infocomm2022" className='btn-primary btn-lg btn'>Infocomm 2022</Link> */}

{/*                 
                <a href="/resources/download/MOPIC Mirror User Guide_KR.pdf" target="_blank" rel="noopener noreferrer" className='btn-primary btn-lg btn'>MOPIC Mirror User Guide (KR)</a>
                <a href="/resources/download/MOPIC Mirror User Guide_EN.pdf" target="_blank" rel="noopener noreferrer" className='btn-primary btn-lg btn'>MOPIC Mirror User Guide (EN)</a>
        
              </div> */}
            </div>
          </div>
          
        </Route>
        <Route exact path="/manual" component={Manual}></Route>
        <Route exact path="/dhaula" component={Dhaula}></Route>
        <Route exact path="/ir" component={Ir}></Route>
        <Route exact path="/ir/kr" component={Ir}></Route>
        <Route exact path="/ir/en" component={Ir_en}></Route>

        <Route exact path="/introduce" component={Introduce}></Route>
        <Route exact path="/introduce/kr" component={Introduce}></Route>
        <Route exact path="/introduce/en" component={Introduce_en}></Route>
        <Route exact path="/introduce/jp" component={Introduce_jp}></Route>

        <Route exact path="/tech/kr" component={Tech}></Route>
        <Route exact path="/tech/en" component={Tech_en}></Route>
        
        <Route exact path="/infocomm2022" component={Infocomm2022}></Route>

        <Route exact path="/learnmore/kr" component={LearnMore}></Route>
        

      </Switch>
    </div>
  );
}

export default App;

