/* eslint-disable */ 
import React, {useEffect, useState} from 'react';
import { Form, Modal, Button} from 'react-bootstrap';
import $ from "jquery";

function Ir_en() {
    useEffect(() => {
      const htmlTitle = document.querySelector("title");
      htmlTitle.innerText = 'Mopic IR';
        window.addEventListener('resize', onResize);
        onResize();
        return function end() {
            console.log('ir end');
        }
    })
    function onResize() {
        let _w = document.getElementsByClassName("ir_wrap")[0].clientWidth - 40;
        let _h = window.innerHeight - 40;
        let img = document.getElementsByClassName('img');
        let i =0;
        
        /*if (_w / _h > 16/9) {
            $('.ir_item .img').css( {
                'width': 'auto',
                'height': _h
            });
        }
        else {
            $('.ir_item .img').css( {
                'width': '100%',
                'height': 'auto'
            });
        }*/
        
        if (_w / _h > 16/9) {
            for (i = 0; i<img.length; i++) {
                img[i].style.width = 'auto';
                img[i].style.height = _h+'px';
            }
        }
        else {
            for (i = 0; i<img.length; i++) {
                img[i].style.width = '100%';
                img[i].style.height = 'auto';
            }
        }
    }

//14,34,35,36,42,44,50
  let [stat, setStat] = useState(false);
  let [pwd, setPwd] = useState("");
  let [certAry, setCertAry] = useState([
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, true, false, false, false, false, false, false, 
    false, false, false, true, false, true, false, true, false, false, 
    false, true, true, true, true, true, false, false, false, false, 
    false, false, false, false, false, false, false, false, false, false
  ]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCert = (e) => {
    

    
    if (pwd === process.env.REACT_APP_PASSWORD) {
      var temp = [];
      for (var i=0;i<certAry.length; i++) {
        temp.push(false);
      }
      setCertAry(temp);
      setStat(true);
    }
    else {
      alert('인증되지 않은 비밀번호입니다.')
    }
    handleClose();
    e.preventDefault();
    return false;
  }
  function Navigation(prop) {
    return null;
    let hideNavi=[0,1,2,8, 14,16,18,21,28,46,47,48,49,50];
    console.log(prop.page, hideNavi.indexOf(prop.page));
    if (hideNavi.indexOf(prop.page) > -1) {

      return null;
    }
    else {
      return (
        <div className='navi_wrap'>
          <button className='btn_navi0' onClick={()=> {pageMove(0)}}></button>
          <button className='btn_navi1' onClick={()=> {pageMove(1)}}></button>
          <button className='btn_navi2' onClick={()=> {pageMove(2)}}></button>
          <button className='btn_navi3' onClick={()=> {pageMove(3)}}></button>
          <button className='btn_navi4' onClick={()=> {pageMove(4)}}></button>
          <button className='btn_navi5' onClick={()=> {pageMove(5)}}></button>
        </div>
      )
    }
  }
  function pageMove(num){
    let pageStart = [0, 8, 21, 28, 36, 43 ];
    //console.log($('.item'+pageStart[num]), $('.item'+pageStart[num]).offset().top);
    $('html, body').animate({scrollTop: $('.item'+pageStart[num]).offset().top}, 0);
    //window.scrollTo($('.item'+pageStart[num]).offset().top, 0);
  }
  function defaultSlide() {
    var resultAry = [];
    var dataAry = [];
    var totalSlide = 50;
    for(var i=0; i<totalSlide; i++) {
      dataAry.push(["ir_item item"+i, "/resources/images/1920_en/Artboard "+(i+1)+".jpg"]);
    }
    for(i=0; i<totalSlide; i++) {
      if (certAry[i] === false) {
        if (i===9) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap" >
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <div className="img_wrap7_en">
                  <img src="/resources/images/1920_en/7p.gif" alt="" className="gif0" />
                </div>
                <img src="/resources/images/1920_en/Artboard 7.png" alt="" className="img26" />
                <Navigation page={i}></Navigation>
              </div>
            </li>
            );
        }
        // else if (i===12) {
        //   resultAry.push(
        //     <li className={dataAry[i][0]} key={i}>
        //       <div className="img_wrap" >
        //         <img src={dataAry[i][1]} alt="Mopic" className="img" />
        //         <img src="/resources/images/1920_en/13p.gif" alt="" className="gif1" />
        //         <Navigation page={i}></Navigation>
        //       </div>
        //     </li>
        //     );
        // }
        else if (i===16) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <iframe className="yt1" src="https://www.youtube-nocookie.com/embed/kLs71qdksAg?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <Navigation page={i}></Navigation>
              </div>
            </li>
            );
        }
        else if (i===18) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
                
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <iframe className="yt5" src="https://www.youtube-nocookie.com/embed/3Bkqi-9Jw60?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <Navigation page={i}></Navigation>
              </div>
            </li>
            );
        }
        else if (i===19) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
                
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <iframe className="yt2_en" src="https://www.youtube-nocookie.com/embed/xDaEf6O0olo?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <Navigation page={i}></Navigation>
              </div>
            </li>
            );
        }
        else if (i===21) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <iframe className="yt3" src="https://www.youtube-nocookie.com/embed/5E7JPnz36Ak?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <iframe className="yt4" src="https://www.youtube-nocookie.com/embed/IZp70HaJUeM?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <Navigation page={i}></Navigation>
              </div>
            </li>
            );
        }
        else if (i===25) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <img src="/resources/images/1920_en/25p_component02_resize.gif" alt="" className="gif25_0_en" />
                <img src="/resources/images/1920_en/25p_component03_resize.gif" alt="" className="gif25_1_en" />
                <img src="/resources/images/1920_en/25p_eyetracking.gif" alt="" className="gif25_2_en" />
                <img src="/resources/images/1920_en/Artboard 23.png" alt="" className="img26" />
                <Navigation page={i}></Navigation>
              </div>
            </li>
            );
        }
        else if (i===28) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <img src="/resources/images/1920_en/25p_eyetracking.gif" alt="" className="gif28_en" />
                <img src="/resources/images/1920_en/26p_senser.gif" alt="" className="gif28-1_en" />
                <Navigation page={i}></Navigation>
              </div>
            </li>
            );
        }
        else if (i===30) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <div className="img_wrap27-1"><img src="/resources/images/1920_en/30p_gif.gif" alt="" className="gif30_1" /></div>
                <div className="img_wrap27-2"><img src="/resources/images/1920_en/30p_gif0.gif" alt="" className="gif30_2" /></div>
                <div className="img_wrap27-3"><img src="/resources/images/1920_en/30p_gif1.gif" alt="" className="gif30_3" /></div>
                <Navigation page={i}></Navigation>
                
              </div>
            </li>
            );
        }
        else if (i===45) {
        }
        else if (i===46) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <a href="https://www.youtube.com/channel/UCqWaRWhgoPbhn0g2w67isxA" className="link0" target={"_blank"} rel="noopener noreferrer"></a>
                <a href="http://www.mopiclabs.com/" className="link1" target={"_blank"} rel="noopener noreferrer"></a>
                <Navigation page={i}></Navigation>
              </div>
            </li>
            );
        }
        else {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <Navigation page={i}></Navigation>
              </div>
            </li>
          );
        }
      }
    }
    return resultAry;
  }


  return (
    <div className="App">
        <div className="ir_wrap en" id="ir">
            <ul className="ir_list">
            { 
                defaultSlide()
            }
            </ul>
            { 
            stat === false
            ? <div className="cert">
                <div className="cert_wrap">
                <div className="cert_inner">
                    <Button variant="primary" onClick={() => {handleShow(); }}>Password</Button>
                </div>
                </div>
            </div>
            : null
            }
            
        </div>

        
        
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>Certification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form action="">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" onSubmit= {handleCert}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    placeholder=""
                    autoFocus
                    onChange= {(e) => {
                    setPwd(e.target.value);
                    }}
                    onKeyDown= {(e) => {
                    if (e.key ==='Enter') {
                        handleCert(e);
                        e.preventDefault();
                        return false;
                    }
                    }}
                />
                </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleCert}>
                Certification
            </Button>
            </Modal.Footer>
        </Modal>
    </div>
  );
}


export default Ir_en;