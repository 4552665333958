/* eslint-disable */ 

import React, {useEffect, useState} from 'react';
import {GetAxiosCommonDescriptior} from '../../hooks/useAxios';
import { axiosPostProductIssued,axiosPostProductModelSNIssued } from "../../hooks/useProduct";
import $ from "jquery";

function Manuals() {
    const [productData, setProductData] = useState('');
    const [check, setCheck] = useState(false);
    const [login, setLogin] = useState(true);
    useEffect(() => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = 'Manual & Download';
        $('body').css('overflow-y','scroll');
        //$('body').addClass('light');
        $('.btn_plugin').on('click', function(e) {
            $('.plugin_desc').show(300);
            e.preventDefault();
        });
        return function end() {
            //console.log('Dhaula Manual end');
        }
    })
    function onEnterKeyDown(event) {
        if (event.key === 'Enter') {
            checkSerialNumber()
        }
    }
    function checkSerialNumber() {
        let serialNumber = $('#serial').val();
        
        let axiosDescriptor = GetAxiosCommonDescriptior(onSuccessProductIssued, onErrorAxiosCommons);
        if (serialNumber.length < 20) {
            axiosDescriptor.data = {model_serialnumber:serialNumber};
            axiosPostProductModelSNIssued(axiosDescriptor);
        }
        else {
            axiosDescriptor.data = {serialnumber:serialNumber};
            axiosPostProductIssued(axiosDescriptor);
        }
    }
    
    const onSuccessProductIssued = (data) => {
        $('.checkin_alert').hide(0);
        setProductData(data.model_name);
        setCheck(true);
    };
    const openManual = () => {
    }
    const onErrorAxiosCommons = (data) => {
      console.log(data);
      $('.checkin_alert').show(0);
      setCheck(false);
      //alert('error')
    }
    const PageCheckIn = () => {
        //if (login) {
            return (
                <div className="checkin manualBox">
                    <div className="checkin_wrap">
                        <div className="checkin_box">
                            <div className="checkin_cont">
                                <div className="checkin_header">
                                    <img src="/resources/images/manual/logo.png" alt="" />
                                </div>
                                <p className="checkin_title">Manual & Download</p>
                                <div className="serial_wrap">
                                    <input type="text" id="serial" className="serial" placeholder="Serial Number" onKeyDown={onEnterKeyDown}/>
                                    <button className="btn" onClick={checkSerialNumber}>Check</button>
                                </div>
                                <p className="checkin_desc">To download the required software, enter the serial number found in the product box or on the back of the product.</p>
                                <p className="checkin_alert">Invalid serial number</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        // }
        // else {
        //     return null;
        // }
    }
    const PageManual = () => {
        if (check) {
            let size = productData.slice(1,3);
            let img, title;
            let hideClass = false;
            if (size === '15') {
                img = "./resources/images/manual/dhaula_prd_04.png";
                title ='Dhaula - 15.6" Light Field 3D Display';
                hideClass = false;
            }
            else if (size === '32') {
                img = "./resources/images/manual/chooyu_prd_00.png";
                title ='Chooyu - 32" Light Field 3D Display';
                hideClass = false;
            }
            else if (size === '65') {
                img = "./resources/images/manual/lhotse_prd_00.png";
                title ='Lhotse - 65" Light Field 3D Display';
                hideClass = false;
            }
            else {
                img="";
                title="";
                hideClass = true;
            }
            setTimeout(() => {
                
                $('.checkin').fadeOut(200);
                $('.download').slideDown(300).addClass('active');
                
            },100);

            return (
                <div className="download manualBox">
                    <div className="download_wrap">
                        <div className={hideClass ? "download_header hideInfo" : "download_header"}>
                            <div className="product_img">
                                <img src={img} alt="" />
                            </div>
                            <p className='title'>{title}</p>
                        </div>
                        <div className="download_cont">
                            <div className="download_box">
                                <div className="download_title">
                                    <p className="title">Download Required Program</p>
                                </div>
                                <ul className="download_list">
                                    <li><a href="https://download.mopiclabs.com/release/mopicmirror/MopicMirror_1.3.2.6_Setup_x64.exe" target='_blink'>MOPIC Mirror (Ver.1.3.2.6)</a></li>
                                    <li><a href="https://download.mopiclabs.com/release/mopicmirror/manual/MOPIC Mirror_1.2.x_User Guide_EN.pdf" target='_blink'>[EN] Manual for MOPIC Mirror (Ver. 1.2.x ~)</a></li>
                                    <li><a href="https://download.mopiclabs.com/release/mopicmirror/manual/MOPIC Mirror_1.2.x_User Guide_KR.pdf" target='_blink'>[KR] Manual for MOPIC Mirror (Ver. 1.2.x ~)</a></li>
                                </ul>
                            </div>
                            <div className="download_box">
                                <div className="download_title">
                                    <p className="title">Download 3D Content</p>
                                </div>
                                <ul className="download_list">
                                    <li><a href="https://download.mopiclabs.com/release/contents/Unity/1.4.2/Gundam_Leap_Dhaula_Jig.zip" target='_blink'>Sample 3D Modeling (with leap motion sensor)</a></li>
                                    <li><a href="https://download.mopiclabs.com/release/contents/stereo_samples.zip" target='_blink'>Sample 3D Videos</a></li>
                                </ul>
                            </div>
                            <div className="download_box">
                                <div className="download_title">
                                    <p className="title">Download Unity Plugin</p>
                                </div>
                                <ul className="download_list">
                                    <li>
                                        {/* <a href="https://download.mopiclabs.com/release/mopicsdk/unitysdk_release/MopicUnitySDK_Release_1.4.2_Core.unitypackage" target='_blink'>MOPIC Plugin for Unity (Ver.1.4.2)</a> */}
                                        <a href="#" className='btn_plugin'>MOPIC Plugin for Unity (Ver.1.4.2)</a>
                                        <div className="plugin_desc">
                                            The evaluation SDK is distributed free of charge; however, signing an agreement is required for its provision. <br />
                                            Please request it via email, and MOPIC will contact you shortly. <br />
                                            <br />
                                            contact: <a href="mailto:jeesoo.jung@mopiclabs.com">jeesoo.jung@mopiclabs.com</a>
                                        </div>
                                    </li>
                                    {/* <li><a href="https://download.mopiclabs.com/release/mopicsdk/unitysdk_release/MopicUnitySDK_Release_1.4.2_Sample.unitypackage" target='_blink'>Sample Scene for Unity (Ver.1.4.2)</a></li> */}
                                    <li><a href="https://download.mopiclabs.com/release/mopicsdk/unitysdk_release/Manual/MOPIC Unity SDK_1.4.x_Manual_EN.pdf" target='_blink'>[EN] Manual for MOPIC Plugin (Ver.1.4.x~)</a></li>
                                    <li><a href="https://download.mopiclabs.com/release/mopicsdk/unitysdk_release/Manual/MOPIC Unity SDK_1.4.x_Manual_KR.pdf" target='_blink'>[KR] Manual for MOPIC Plugin (Ver.1.4.x~)</a></li>
                                    <li><a href="https://download.mopiclabs.com/release/mopicsdk/unitysdk_release/Documents/API%20Description_1.4.2.xlsx" target='_blink'>[KR] API Manual (Ver.1.4.x~)</a></li>
                                </ul>
                            </div>
                            <div className="download_box">
                                <div className="download_title requirements">
                                    <p className="title">Minimum PC requirements</p>
                                </div>
                                <div className="download_notice">
                                    <p className="tit">We recommend using a PC with the following specifications.</p>
                                    <ul className="notice_list">
                                        <li>CPU : Intel i7, 10th gen or better</li>
                                        <li>GPU : Nvidia GeForce RTX 2080 Ti or GeForce RTX 30xx</li>
                                        <li>Windows OS Version: Windows10 1903(May 2019 update) or later</li>
                                        <li>RAM : 16GB dual channel or better</li>
                                        <li>GPU Memory: 6GB or better</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="download_box">
                                <div className="download_title note">
                                    <p className="title">NOTE!</p>
                                </div>
                                <div className="download_notice">
                                    <ul className="notice_list">
                                        <li>Be sure that all cables are connected properly. The camera must be connected via a USB 3.0 cable or higher, so please use the included genuine cable.</li>
                                        <li>Please confirm that MOPIC display is set to 4K (3840*2160).</li>
                                        <li>The sample content ( Gundam.exe ) provided should be run with MOPIC Mirror closed.</li>
                                        <li>When you connect a product to the Internet for the first time, license authorization is automatically completed. If you fail to get a license, please check the firewall settings.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }
  return (
    <div className="App">
    
        <div className="root_wrap manualWrap">
            <div className="manualCont">
                    
                <PageCheckIn />
                <PageManual />
            
            </div>
        </div>
        
        
    </div>
  );
}


export default Manuals;
