/* eslint-disable */ 

import React, {useEffect, useState} from 'react';
import { Form, Modal, Button} from 'react-bootstrap';
//import $ from "jquery";

function LearnMore() {
    useEffect(() => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = 'More Info';
        window.addEventListener('resize', onResize);
        onResize();
        return function end() {
            console.log('introduce end');
        }
    })
    function onResize() {
        let _w = document.getElementsByClassName("introduce_wrap")[0].clientWidth - 40;
        let _h = window.innerHeight - 40;
        let img = document.getElementsByClassName('img');
        let i =0;
        
        
        if (_w / _h > 16/9) {
            for (i = 0; i<img.length; i++) {
                img[i].style.width = 'auto';
                img[i].style.height = _h+'px';
            }
        }
        else {
            for (i = 0; i<img.length; i++) {
                img[i].style.width = '100%';
                img[i].style.height = 'auto';
            }
        }
    }


  let [stat, setStat] = useState(false);
  let [pwd, setPwd] = useState("");
  let [certAry, setCertAry] = useState([
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false
  ]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const secret = ["1","1","0","3"];
  const handleCert = (e) => {
    if (pwd === secret.join('')) {
      var temp = [];
      for (var i=0;i<certAry.length; i++) {
        temp.push(false);
      }
      setCertAry(temp);
      setStat(true);
    }
    else {
      alert('인증되지 않은 비밀번호입니다.')
    }
    handleClose();
    e.preventDefault();
    return false;
  }

  function defaultSlide() {
    var resultAry = [];
    var dataAry = [];
    //var totalSlide = 59;
    var totalSlide = 12;
    for(var i=0; i<totalSlide; i++) {
      dataAry.push(["introduce_item item"+i, "/resources/images/learnmore/Artboard "+(i+1)+".jpg"]);
    }
    for(i=7; i<totalSlide; i++) {
      if (certAry[i] === false) {
        if (i===9) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
                <div className="img_wrap" >
                    <img src={dataAry[i][1]} alt="Mopic" className="img" />
                    <img src="/resources/images/learnmore/10p.gif" alt="" className="gif1" />
                    <img src="/resources/images/learnmore/Artboard 10_text.png" alt="" className="txt1" />
                    
                </div>
            </li>
            );
        }
        else if (i===10) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <img src="/resources/images/learnmore/11p-01.gif" alt="" className="gif2" />
                <img src="/resources/images/learnmore/11p-02.gif" alt="" className="gif3" />
                <img src="/resources/images/learnmore/11p-03.gif" alt="" className="gif4" />
                <img src="/resources/images/learnmore/Artboard 11_text.png" alt="" className="txt2" />
              </div>
            </li>
            );
        }
        else if (i===11) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
                
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <img src="/resources/images/learnmore/12p.gif" alt="" className="gif5" />
              </div>
            </li>
            );
        }
        else {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <img src={dataAry[i][1]} alt="Mopic" className="img" />
            </li>
          );
        }
      }
    }
    return resultAry;
  }


  return (
    <div className="App">
        <div className="introduce_wrap" id="ir">
            <ul className="introduce_list">
            { 
                defaultSlide()
            }
            </ul>
            { /*
            stat === false
            ? <div className="cert">
                <div className="cert_wrap">
                <div className="cert_inner">
                    <Button variant="primary" onClick={() => {handleShow(); }}>Password</Button>
                </div>
                </div>
            </div>
            : null
        */}
            
        </div>

        
        
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>Certification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form action="">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" onSubmit= {handleCert}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    placeholder=""
                    autoFocus
                    onChange= {(e) => {
                    setPwd(e.target.value);
                    }}
                    onKeyDown= {(e) => {
                    if (e.key ==='Enter') {
                        handleCert(e);
                        e.preventDefault();
                        return false;
                    }
                    }}
                />
                </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleCert}>
                Certification
            </Button>
            </Modal.Footer>
        </Modal>
    </div>
  );
}


export default LearnMore;
