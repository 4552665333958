/* eslint-disable */ 

import React, {useEffect, useState} from 'react';
import { Form, Modal, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
//import $ from "jquery";

function Infocomm2022() {
    useEffect(() => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = 'Mopic ';
        window.addEventListener('resize', onResize);
        onResize();
        return function end() {
            console.log('introduce end');
        }
    })
    function onResize() {
        let _w = document.getElementsByClassName("introduce_wrap")[0].clientWidth - 40;
        let _h = window.innerHeight - 40;
        let img = document.getElementsByClassName('img');
        let i =0;
        
        
        if (_w / _h > 16/9) {
            for (i = 0; i<img.length; i++) {
                img[i].style.width = 'auto';
                img[i].style.height = _h+'px';
            }
        }
        else {
            for (i = 0; i<img.length; i++) {
                img[i].style.width = '100%';
                img[i].style.height = 'auto';
            }
        }
    }


  let [stat, setStat] = useState(false);
  let [pwd, setPwd] = useState("");
  let [certAry, setCertAry] = useState([
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false
  ]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const secret = ["1","1","0","3"];
  const handleCert = (e) => {
    if (pwd === secret.join('')) {
      var temp = [];
      for (var i=0;i<certAry.length; i++) {
        temp.push(false);
      }
      setCertAry(temp);
      setStat(true);
    }
    else {
      alert('인증되지 않은 비밀번호입니다.')
    }
    handleClose();
    e.preventDefault();
    return false;
  }

  function defaultSlide() {
    var resultAry = [];
    var dataAry = [];
    //var totalSlide = 59;
    var totalSlide = 8;
    for(var i=0; i<totalSlide; i++) {
      dataAry.push(["introduce_item item"+i, "/resources/images/infocomm/Artboard "+(i+1)+".jpg"]);
    }
    for(i=0; i<totalSlide; i++) {
      if (certAry[i] === false) {
        if (i===0) {
          resultAry.push(
            <li className={dataAry[i][0]}>
                <div className="img_wrap" >
                    <img src={dataAry[i][1]} alt="Mopic" className="img" />
                    <a href="mailto:andrew.kwon@mopiclabs.com" className="infocomm_link_11" target="_blank"></a>
                    <a href="mailto:jeesoo.jung@mopiclabs.com" className="infocomm_link_12" target="_blank"></a>
                    <a href="http://www.mopiclabs.com/en" className="infocomm_link_13" target="_blank"></a>
                    
                </div>
            </li>
            );
        }
        else if (i===3) {
          resultAry.push(
            <li className={dataAry[i][0]}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <iframe className="infocomm_yt1" src="https://www.youtube-nocookie.com/embed/Q9x4T3CrknI?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe className="infocomm_yt2" src="https://www.youtube-nocookie.com/embed/q98dzb5hInw?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </li>
            );
        }
        else if (i===6) {
          resultAry.push(
            <li className={dataAry[i][0]}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <iframe className="infocomm_yt3" src="https://www.youtube-nocookie.com/embed/MEOSFBOT1zQ?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe className="infocomm_yt4" src="https://www.youtube-nocookie.com/embed/5E7JPnz36Ak?rel=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </li>
            );
        }
        else if (i===7) {
          resultAry.push(
            <li className={dataAry[i][0]}>
                
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <a href="mailto:andrew.kwon@mopiclabs.com" className="infocomm_link_81" target="_blank"></a>
                <a href="mailto:jeesoo.jung@mopiclabs.com" className="infocomm_link_82" target="_blank"></a>
                <a href="http://www.mopiclabs.com/en" className="infocomm_link_83" target="_blank"></a>
                <img src="/resources/images/infocomm/eyeTracking_on.gif" alt="" className="infocomm_gif2" />
                <img src="/resources/images/infocomm/Viewer_girl-2.gif" alt="" className="infocomm_gif3" />
              </div>
            </li>
            );
        }
        else {
          resultAry.push(
            <li className={dataAry[i][0]}>
              <img src={dataAry[i][1]} alt="Mopic" className="img" />
            </li>
          );
        }
      }
    }
    return resultAry;
  }


  return (
    <div className="App">
        <div className="introduce_wrap" id="ir">
            <ul className="introduce_list">
            { 
                defaultSlide()
            }
            </ul>
            <div className="cert">
                <div className="cert_wrap">
                <div className="cert_inner">
                    <Button variant="primary" component={Link} href="/resources/images/infocomm/infocomm_brochure.pdf" target="_blank">PDF Download</Button>
                </div>
                </div>
            </div>
            
        </div>

        
        
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>Certification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form action="">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" onSubmit= {handleCert}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    placeholder=""
                    autoFocus
                    onChange= {(e) => {
                    setPwd(e.target.value);
                    }}
                    onKeyDown= {(e) => {
                    if (e.key ==='Enter') {
                        handleCert(e);
                        e.preventDefault();
                        return false;
                    }
                    }}
                />
                </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleCert}>
                Certification
            </Button>
            </Modal.Footer>
        </Modal>
    </div>
  );
}

export default Infocomm2022;
