/* eslint-disable */ 

import React, {useEffect, useState} from 'react';
import { Form, Modal, Button} from 'react-bootstrap';
//import $ from "jquery";

function Introduce() {
    useEffect(() => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = 'Mopic 회사소개서';
        window.addEventListener('resize', onResize);
        onResize();
        return function end() {
            console.log('introduce end');
        }
    })
    function onResize() {
        let _w = document.getElementsByClassName("introduce_wrap")[0].clientWidth - 40;
        let _h = window.innerHeight - 40;
        let img = document.getElementsByClassName('img');
        let i =0;
        
        
        if (_w / _h > 16/9) {
            for (i = 0; i<img.length; i++) {
                img[i].style.width = 'auto';
                img[i].style.height = _h+'px';
            }
        }
        else {
            for (i = 0; i<img.length; i++) {
                img[i].style.width = '100%';
                img[i].style.height = 'auto';
            }
        }
    }


  let [stat, setStat] = useState(false);
  let [pwd, setPwd] = useState("");
  let [certAry, setCertAry] = useState([
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false
  ]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const secret = ["1","1","0","3"];
  const handleCert = (e) => {
    if (pwd === secret.join('')) {
      var temp = [];
      for (var i=0;i<certAry.length; i++) {
        temp.push(false);
      }
      setCertAry(temp);
      setStat(true);
    }
    else {
      alert('인증되지 않은 비밀번호입니다.')
    }
    handleClose();
    e.preventDefault();
    return false;
  }

  function defaultSlide() {
    var resultAry = [];
    var dataAry = [];
    //var totalSlide = 59;
    var totalSlide = 19;
    for(var i=0; i<totalSlide; i++) {
      dataAry.push(["introduce_item item"+i, "/resources/images/introduce_2023/kr/intro"+(i+1)+".jpg"]);
    }
    for(i=0; i<totalSlide; i++) {
      if (certAry[i] === false) {
        if (i===0) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
                <div className="img_wrap" >
                    <img src="/resources/images/introduce_2023/kr/intro1.gif" alt="" className="img" />
                    <img src="/resources/images/introduce_2023/kr/intro1.png" alt="" className="intro_txt1" />
                    
                </div>
            </li>
            );
        }
        else if (i===11) {
        }
        else if (i===3) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
                <div className="img_wrap" >
                    <img src="/resources/images/introduce_2023/kr/intro4.gif" alt="" className="img" />
                    <img src="/resources/images/introduce_2023/kr/intro4.png" alt="" className="intro_txt1" />
                    
                </div>
            </li>
            );
        }
        else if (i===10) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <img src="/resources/images/introduce_2023/kr/image19.gif" alt="" className="intro_gif2" />
                <img src="/resources/images/introduce_2023/kr/image20.gif" alt="" className="intro_gif3" />
                <img src="/resources/images/introduce_2023/kr/image21.gif" alt="" className="intro_gif4" />
                <img src="/resources/images/introduce_2023/kr/intro11.png" alt="" className="intro_txt2" />
              </div>
            </li>
            );
        }
        else if (i===15) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <iframe className="intro_yt0" src="https://www.youtube-nocookie.com/embed/xDaEf6O0olo?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </li>
            );
        }
        else if (i===18) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                
                <a href="mailto:info@mopic.co.kr" className="intro_mail" target={"_blank"} rel="noopener noreferrer"></a>
              </div>
            </li>
            );
        }
        else if (i===9909) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
                <div className="img_wrap" >
                    <img src={dataAry[i][1]} alt="Mopic" className="img" />
                    <img src="/resources/images/introduce/10p.gif" alt="" className="gif1" />
                    <img src="/resources/images/introduce/Artboard 10_text.png" alt="" className="txt1" />
                    
                </div>
            </li>
            );
        }
        else if (i===99010) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <img src="/resources/images/introduce/11p-01.gif" alt="" className="gif2" />
                <img src="/resources/images/introduce/11p-02.gif" alt="" className="gif3" />
                <img src="/resources/images/introduce/11p-03.gif" alt="" className="gif4" />
                <img src="/resources/images/introduce/Artboard 11_text.png" alt="" className="txt2" />
              </div>
            </li>
            );
        }
        else if (i===99011) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
                
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <img src="/resources/images/introduce/12p.gif" alt="" className="gif5" />
              </div>
            </li>
            );
        }
        else if (i===99021) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <iframe className="yt5" src="https://www.youtube-nocookie.com/embed/3Bkqi-9Jw60?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </li>
            );
        }
        else if (i===99022) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <iframe className="yt1" src="https://www.youtube-nocookie.com/embed/xDaEf6O0olo?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </li>
            );
        }
        else if (i===99023) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <iframe className="yt3" src="https://www.youtube-nocookie.com/embed/5E7JPnz36Ak?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <iframe className="yt4" src="https://www.youtube-nocookie.com/embed/IZp70HaJUeM?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </li>
            );
        }
        else if (i===99024) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <iframe className="yt11" src="https://www.youtube-nocookie.com/embed/CGeJqNCAMxM?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <iframe className="yt12" src="https://www.youtube-nocookie.com/embed/38hpq2zSaKo?rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              </div>
            </li>
            );
        }
        else if (i===99028) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                
                <a href="https://www.youtube.com/channel/UCqWaRWhgoPbhn0g2w67isxA" className="link0" target={"_blank"} rel="noopener noreferrer"></a>
                <a href="http://www.mopiclabs.com/" className="link1" target={"_blank"} rel="noopener noreferrer"></a>
                <a href="http://www.mopiclabs.com/" className="link2" target={"_blank"} rel="noopener noreferrer"></a>
              </div>
            </li>
            );
        }
        else if (i===99043) {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <img src="/resources/images/introduce/44p.gif" alt="" className="gif6" />
              </div>
            </li>
            );
        }
        else {
          resultAry.push(
            <li className={dataAry[i][0]} key={i}>
              <img src={dataAry[i][1]} alt="Mopic" className="img" />
            </li>
          );
        }
      }
    }
    return resultAry;
  }


  return (
    <div className="App">
        <div className="introduce_wrap" id="ir">
            <ul className="introduce_list">
            { 
                defaultSlide()
            }
            </ul>
            { /*
            stat === false
            ? <div className="cert">
                <div className="cert_wrap">
                <div className="cert_inner">
                    <Button variant="primary" onClick={() => {handleShow(); }}>Password</Button>
                </div>
                </div>
            </div>
            : null
        */}
            
        </div>

        
        
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>Certification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form action="">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" onSubmit= {handleCert}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    placeholder=""
                    autoFocus
                    onChange= {(e) => {
                    setPwd(e.target.value);
                    }}
                    onKeyDown= {(e) => {
                    if (e.key ==='Enter') {
                        handleCert(e);
                        e.preventDefault();
                        return false;
                    }
                    }}
                />
                </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleCert}>
                Certification
            </Button>
            </Modal.Footer>
        </Modal>
    </div>
  );
}


export default Introduce;
