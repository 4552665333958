/* eslint-disable */ 

import React, {useEffect, useState} from 'react';
import { Form, Modal, Button} from 'react-bootstrap';
//import $ from "jquery";

function Tech_en() {

    useEffect(() => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = 'Mopic Technical Overview';
        window.addEventListener('resize', onResize);
        onResize();
        return function end() {
            //console.log('introduce end');
        }
        
    })
    function onResize() {
        let _w = document.getElementsByClassName("tech_wrap")[0].clientWidth - 40;
        let _h = window.innerHeight - 40;
        let img = document.getElementsByClassName('img');
        let i =0;
        
        
        if (_w / _h > 16/9) {
            for (i = 0; i<img.length; i++) {
                img[i].style.width = 'auto';
                img[i].style.height = _h+'px';
            }
        }
        else {
            for (i = 0; i<img.length; i++) {
                img[i].style.width = '100%';
                img[i].style.height = 'auto';
            }
        }
    }


  let [stat, setStat] = useState(false);
  let [pwd, setPwd] = useState("");
  let [certAry, setCertAry] = useState([
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false,
    false, false, false, false, false, false, false, false, false, false
  ]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const secret = ["1","1","0","3"];
  const handleCert = (e) => {
    if (pwd === secret.join('')) {
      var temp = [];
      for (var i=0;i<certAry.length; i++) {
        temp.push(false);
      }
      setCertAry(temp);
      setStat(true);
    }
    else {
      alert('인증되지 않은 비밀번호입니다.')
    }
    handleClose();
    e.preventDefault();
    return false;
  }

  function defaultSlide() {
    var resultAry = [];
    var dataAry = [];
    //var totalSlide = 59;
    var totalSlide = 23;
    for(var i=0; i<totalSlide; i++) {
      dataAry.push(["tech_item item"+i, "/resources/images/tech_en_0219/tech_"+i+".jpg"]);
    }
    for(i=0; i<totalSlide; i++) {
      if (certAry[i] === false) {
        if (i===2) {
          resultAry.push(
            <li className={dataAry[i][0]} key={"tech" + i}>
                <div className="img_wrap" >
                    <img src={dataAry[i][1]} alt="Mopic" className="img" />
                    <div className="video_wrap gif_wrap gif1_en">
                      <video playsInline autoPlay muted loop id="lf_video3" className="img_gif">
                        <source src="/resources/images/tech_en_0219/main_movie.mp4" type="video/mp4" />
                      </video>
                    </div>
                    <img src="/resources/images/tech_en_0219/tech_float2.png" alt="" className="float" />
                    
                </div>
            </li>
            );
        }
        else if (i==3) {
          resultAry.push(
            <li className={dataAry[i][0]} key={"tech" + i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <div className="gif_wrap gif2">
                  <img src="/resources/images/tech_en_0219/image11.gif" alt="" className="img_gif" />
                </div>
                <img src="/resources/images/tech_en_0219/image25.gif" alt="" className="gif3" />
                <img src="/resources/images/tech_en_0219/tech_floor3.png" alt="" className="float" />
                <img src="/resources/images/tech_en_0219/image26.gif" alt="" className="gif4" />
                <img src="/resources/images/tech_en_0219/tech_float3.png" alt="" className="float" />
              </div>
            </li>
            );
        }
        else if (i===4) {
          resultAry.push(
            <li className={dataAry[i][0]} key={"tech" + i}>
                
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                {/* <img src="/resources/images/tech/5p_Mopic-unity.gif" alt="" className="gif3" /> */}
                <div className="video_wrap gif5">
                  <video playsInline autoPlay muted loop id="lf_video3">
                    <source src="/resources/images/tech/05.Lightfield_view_Dynamic-View Light Field 3D.mp4" type="video/mp4" />
                  </video>
                </div>
                <img src="/resources/images/tech_en_0219/30p_gif1.gif" alt="" className="gif6" />
                <img src="/resources/images/tech_en_0219/30p_gif.gif" alt="" className="gif7" />
                <img src="/resources/images/tech_en_0219/tech_float4.png" alt="" className="float" />
              </div>
            </li>
            );
        }
        else if (i===13) {
          resultAry.push(
            <li className={dataAry[i][0]} key={"tech" + i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <img src="/resources/images/tech_en_0219/image26.gif" alt="" className="gif8" />
                <img src="/resources/images/tech_en_0219/26p_senser.gif" alt="" className="gif9" />
                <img src="/resources/images/tech_en_0219/tech_float13.png" alt="" className="float" />
              </div>
            </li>
            );
        }
        else if (i===16) {
          resultAry.push(
            <li className={dataAry[i][0]} key={"tech" + i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <img src="/resources/images/tech_en_0219/image69.gif" alt="" className="gif10" />
              </div>
            </li>
            );
        }
        else if (i===22) {
          resultAry.push(
            <li className={dataAry[i][0]} key={"tech" + i}>
              <div className="img_wrap">
                <img src={dataAry[i][1]} alt="Mopic" className="img" />
                <a href="https://www.youtube.com/channel/UCqWaRWhgoPbhn0g2w67isxA/videos" className="link0" target={"_blank"} rel="noopener noreferrer"></a>
                <a href="http://www.mopiclabs.com/" className="link1" target={"_blank"} rel="noopener noreferrer"></a>
              </div>
            </li>
            );
        }

        else {
          resultAry.push(
            <li className={dataAry[i][0]} key={"tech" + i}>
              <img src={dataAry[i][1]} alt="Mopic" className="img" />
            </li>
          );
        }
      }
    }
    return resultAry;
  }


  return (
    <div className="App">
        <div className="tech_wrap" id="ir" key="tech_en_wrap">
            <ul className="tech_list">
            { 
                defaultSlide()
            }
            </ul>
            { /*
            stat === false
            ? <div className="cert">
                <div className="cert_wrap">
                <div className="cert_inner">
                    <Button variant="primary" onClick={() => {handleShow(); }}>Password</Button>
                </div>
                </div>
            </div>
            : null
        */}
            
        </div>

        
        
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
            <Modal.Title>Certification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form action="">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" onSubmit= {handleCert}>
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    placeholder=""
                    autoFocus
                    onChange= {(e) => {
                    setPwd(e.target.value);
                    }}
                    onKeyDown= {(e) => {
                    if (e.key ==='Enter') {
                        handleCert(e);
                        e.preventDefault();
                        return false;
                    }
                    }}
                />
                </Form.Group>
            </Form>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={handleCert}>
                Certification
            </Button>
            </Modal.Footer>
        </Modal>
    </div>
  );
}


export default Tech_en;
