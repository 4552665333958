/* eslint-disable */ 

import React, {useEffect, useState} from 'react';

function Dhaula() {
    useEffect(() => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = 'Dhaula Manual';
        return function end() {
            //console.log('Dhaula Manual end');
        }
    })

  return (
    <div className="App">
    
        <div className="root_wrap gate">
            <div className="header_wrap">
              <header className="header main-header">
                <nav className="header_nav wrap_padding">
                  <i className="header_logo"><span className="blind-text">MOPIC</span></i>
                </nav>
              </header>
            </div>
            <div className="cont manuals dhaula">
                <div className="cont_wrap">
                    <div className="cont_box">
                        <p className="product_name">U15A730</p>
                        <div className="cols_wrap"> 
                            <div className="col2 img_box">
                                <img src="./resources/images/manual/dhaula/side.png" alt="" />
                            </div>
                            <div className="col2 link_box">
                                <div className="link_cont">
                                    <dl>
                                        <dt>DOWNLOAD USER GUIDE</dt>
                                        <dd>
                                            <a href="/resources/download/U15A730Quick Setup Guide_A5_20230321.pdf" target="_blank">U15A730 User Guide (EN)</a>
                                        </dd>
                                        {/* <dd>
                                            <a href="#" target="_blank">U15A730 User Guide (KR)</a>
                                        </dd> */}
                                    </dl>
                                    <dl>
                                        <dt>DOWNLOAD SUPPORTED SOFTWARE</dt>
                                        <dd>
                                            <a href="https://download.mopiclabs.com/release/mopicmirror/MopicMirror_1.2.8.2_Setup_x64.exe" target="_blank">MOPIC Mirror.exe</a>
                                        </dd>
                                        <dd>
                                            <a href="https://company.mopiclabs.net/resources/download/MOPIC%20Mirror_1.2.x_User%20Guide_EN.pdf" target="_blank">MOPIC Mirror User Guide (EN)</a>
                                        </dd>
                                        <dd>
                                            <a href="https://company.mopiclabs.net/resources/download/MOPIC%20Mirror_1.2.x_User%20Guide_KR.pdf" target="_blank">MOPIC Mirror User Guide (KR)</a>
                                        </dd>
                                    </dl>
                                    <p className='txt'>
                                        * MOPIC Mirror is a software for Windows. Download it from PC.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        
    </div>
  );
}


export default Dhaula;
